html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@keyframes AnimationName {
  0% {
    background-position: 11% 0;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 11% 0;
  }
}

html {
  color: #fff7f8;
  background: linear-gradient(140deg, #2e89a0, #efaac7, #79c2d4) 0 0 / 600% 600%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  animation: 30s infinite AnimationName;
}

body {
  padding: 0 20px;
}

.content {
  flex-direction: column;
  justify-content: start;
  max-width: 1400px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}

h1 {
  margin-bottom: .2em;
  font-size: 12vw;
  font-weight: 800;
}

h1 > span {
  -webkit-text-decoration: underline #df7aa4;
  text-decoration: underline #df7aa4;
}

h2 {
  margin-bottom: .2em;
  font-size: 2em;
  font-weight: 800;
}

.old {
  margin-top: auto;
  margin-bottom: 1em;
}

ul {
  font-size: 6.5vw;
  font-weight: 200;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
}

ul.old {
  font-size: 1em;
}

ul li {
  white-space: nowrap;
  padding-bottom: .2em;
}

ul a {
  color: #fff7f8;
  font-weight: 500;
  text-decoration: none;
}

ul a:hover {
  color: #fff7f8;
  text-decoration: underline;
}

.js .fit {
  white-space: nowrap;
  display: inline-block;
}

/*# sourceMappingURL=index.74769618.css.map */
