@import 'node_modules/reset-css/reset';

@-webkit-keyframes AnimationName {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}
@keyframes AnimationName {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}

html {
  color: rgb(255, 247, 248);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100%;
  background: linear-gradient(140deg, #2e89a0, #efaac7, #79c2d4);

  background-size: 600% 600%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

body {
  padding: 0 20px;
}

.content {
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 100vh;
}

h1 {
  font-size: 12vw;
  font-weight: 800;
  margin-bottom: 0.2em;
  & > span {
  text-decoration: underline;
    text-decoration-color: #df7aa4;
  }
}

h2 {
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 0.2em;
}

.old {
  margin-top: auto;
  margin-bottom: 1em;
}

ul {
  font-size: 6.5vw;
  font-weight: 200;
  list-style-type: decimal-leading-zero;
  list-style-position: inside;

  &.old {
    font-size: 1em;
  }

  li {
    padding-bottom: 0.2em;
    white-space: nowrap;
  }

  a {
    color: rgb(255, 247, 248);
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      color: rgb(255, 247, 248);
    }
  }
}

.js .fit {
  display: inline-block;
  white-space: nowrap;
}
